<template>
    <div id="user_list">
        <el-card>
            <AdminTopOper> </AdminTopOper>

            <!-- 表格主体 -->
            <el-table :data="pageData.rows">
                <el-table-column prop="id" label="编号" width="100" />
                <el-table-column label="文件名" prop="filename" min-width="160" show-overflow-tooltip />
                <el-table-column label="用户ID" prop="userId" width="90" show-overflow-tooltip />
                <el-table-column prop="contact" label="上传用户" width="160">
                    <template #default="scope">
                        <div class="orderContactBox">
                            <img class="orderContactPortrain" :src="scope.row.portrain" alt="">
                            {{ scope.row.username }}
                        </div>

                    </template>
                </el-table-column>
                <el-table-column prop="contact" label="上传方式" width="160">
                    <template #default="scope">
                        {{ scope.row.uploadMethod }}
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="contact" label="来源" width="160">
                    <template #default="scope">
                        {{ JSON.parse(scope.row.introduction).source }}
                    </template>
                </el-table-column> -->
                <el-table-column label="页数" prop="pageCount" width="80" />
                <!-- <el-table-column prop="sort" label="排序" width="80" /> -->
                <el-table-column prop="createdAt" label="创建时间" width="220" />
                <AdminTableButton :width="300">
                    <template #default="scope">
                        <!-- <el-button @click="handleAdminCard(scope.row.id, scope.row.classifyId)" icon="Rank">详情</el-button> -->
                        <el-button @click="click_copyUrl(scope.row)" icon="Rank">复制链接</el-button>
                    </template>
                </AdminTableButton>
            </el-table>

            <AdminPagination> </AdminPagination>
        </el-card>


    </div>
</template>
  
<script setup >
import { ref, onMounted, reactive } from 'vue'
import Api from '@/network'
import { ElMessage, ElMessageBox } from 'element-plus'
const pageData = reactive(Api.adminPage('file'))
pageData.where.count = null
onMounted(pageData.getPage())


const click_copyUrl = (file) => {
  //拿到想要复制的值
  let copyInput = document.createElement('input');//创建input元素
  document.body.appendChild(copyInput);//向页面底部追加输入框
  copyInput.setAttribute('value', file.url);//添加属性，将url赋值给input元素的value属性
  copyInput.select();//选择input元素
  document.execCommand("Copy");//执行复制命令
  ElMessage({
    type: 'success',
    message: '复制下载地址成功'
  });
  copyInput.remove()
}


</script>
  
  

<style lang="less">
.orderContactBox {
    display: flex;
    flex-direction: row;
    align-items: center;

    .orderContactPortrain {
        width: 30px;
        border-radius: 4px;
        margin-right: 6px;
    }
}
</style>
  